/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// import useSWR from 'swr';
import { NextSeo } from "next-seo";
import { motion } from "framer-motion";
// import { useRouter } from 'next/router';
import { styled } from "@stitches/react";
// import ReactPlayer from "react-player/lazy";
import { useEffect, useRef, useState } from "react";
import Head from "next/head";
import useAudioStore from "../../store/audio.store";
import useGuestCoverStore from "../../store/guestCover.store";
import { IndexMomentCover } from "../../components/main/modules/moment/layouts/IndexMomentCover";
import { IndexMomentDetail } from "../../components/main/modules/moment/layouts/IndexMomentDetail";
import { apiURL } from "../../utils/appConfig";
import { getHostMapping } from "../../utils/hostConfig";

const Moment = ({ momentData, comments, events, url, cover }) => {
  const audioRef = useRef(null);
  const iframeRef = useRef(null);
  const widgetRef = useRef(null);
  const playing = useAudioStore(
    (state: { audioPlay: boolean }) => state.audioPlay
  );

  const [ytPlayer, setYtPlayer] = useState(null);
  const [isYTReady, setIsYTReady] = useState(false);

  const guestCover = useGuestCoverStore(
    (state: { guestCover: boolean }) => state.guestCover
  );
  // const guestCover = false;
  // const dataId = moment.data.length > 0 ? moment.data[0].id : null;
  const data = momentData?.length > 0 ? momentData[0] : null;
  const event = events || null;

  const [showPage, setShowPage] = useState(null);

  const backsoundUrl =
    data?.backsoundUrl === "" || !data?.backsoundUrl
      ? data?.backsound?.audioFile.url
      : data?.backsoundUrl;

  useEffect(() => {
    // Initialize YouTube player
    if (data?.backsoundUrl?.includes("music.youtube.com")) {
      const videoId = extractYouTubeMusicId(data.backsoundUrl);

      // Handle YouTube API loading
      if (!(window as any).YT) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        (window as any).onYouTubeIframeAPIReady = initializeYouTubePlayer;
      } else {
        initializeYouTubePlayer();
      }

      function initializeYouTubePlayer() {
        if (iframeRef.current && videoId) {
          const player = new (window as any).YT.Player(iframeRef.current, {
            videoId: videoId,
            playerVars: {
              autoplay: playing ? 1 : 0,
              controls: 0,
              disablekb: 1,
              loop: 1,
              playlist: videoId,
              playsinline: 1, // Enable inline playback on iOS
              origin: window.location.origin,
              enablejsapi: 1,
              modestbranding: 1,
              rel: 0,
              showinfo: 0,
              fs: 0, // Disable fullscreen button
            },
            events: {
              onReady: (event) => {
                setYtPlayer(event.target);
                setIsYTReady(true);
                event.target.setVolume(50);
                if (playing) {
                  event.target.playVideo();
                }
              },
              onStateChange: (event) => {
                // Handle state changes
                if (
                  event.data === (window as any).YT.PlayerState.ENDED &&
                  playing
                ) {
                  event.target.playVideo();
                }
                // Handle mobile autoplay restrictions
                if (
                  event.data === (window as any).YT.PlayerState.UNSTARTED &&
                  playing
                ) {
                  event.target.playVideo();
                }
              },
              onError: (event) => {
                console.warn("YouTube player error:", event.data);
                // Attempt to recover from errors
                if (ytPlayer) {
                  ytPlayer.playVideo();
                }
              },
            },
          });
        }
      }

      // Cleanup function
      return () => {
        if (ytPlayer) {
          ytPlayer.destroy();
        }
      };
    }

    // Handle regular audio
    if (audioRef.current) {
      if (playing) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.warn("Audio playback error:", error);
          });
        }
      } else {
        audioRef.current.pause();
      }
    }
  }, [playing, data]);

  const getBacksoundUrl = () => {
    if (!data?.showBacksound) return null;

    if (data?.backsoundUrl) {
      let url = data.backsoundUrl;

      // Only accept direct SoundCloud URLs
      if (url.includes("soundcloud.com")) {
        // Remove query parameters if any
        url = url.split("?")[0];
        // Ensure URL format is correct
        if (!url.match(/^https:\/\/soundcloud\.com\/[\w-]+\/[\w-]+$/)) {
          console.warn(
            "Invalid SoundCloud URL format. Please use format: https://soundcloud.com/username/track-name"
          );
          return null;
        }
        return url;
      }
    }

    return data?.backsound?.audioFile?.url || null;
  };

  const logoBahagiaDay =
    "https://bahagia.s3.ap-southeast-3.amazonaws.com/dev/bahagia_default_image_0f97890872.jpg";

  useEffect(() => {
    setShowPage(true);
  }, []);

  const extractYouTubeMusicId = (url: string) => {
    const regex = /[?&]v=([^&]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  if (!data) {
    return (
      <div className="grid min-h-screen content-center">
        <div className="flex justify-center">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="text-center"
          >
            Undangan tidak ada
          </motion.p>
        </div>
      </div>
    );
  }

  const activePeriodDate = new Date(data?.activePeriod);
  const eventActivePeriodDate = new Date(event && event?.activePeriod);

  // Calculate daysUntilDue
  const daysUntilDue = Math.ceil(
    (activePeriodDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  );

  const eventDaysUntilDue = Math.ceil(
    (eventActivePeriodDate.getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const title = data?.coverStyle?.title || "";
  const customName = data?.coverStyle?.customName || "";
  const customName2 = data?.coverStyle?.customName2 || "";

  const metaDescription = `${title} ${customName} ${customName2}`.trim() || url;

  const isExpired = event?.activePeriod
    ? event?.activePeriod && eventDaysUntilDue < 0
    : data?.activePeriod && daysUntilDue < 0;

  return isExpired ? (
    <div className="grid min-h-screen content-center">
      <div className="flex justify-center">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="text-center"
        >
          Undangan Sudah Kadaluarsa
        </motion.p>
      </div>
    </div>
  ) : (
    <>
      <Head>
        <link rel="icon" href={logoBahagiaDay} />
        <script src="https://w.soundcloud.com/player/api.js"></script>
        <script src="https://www.youtube.com/iframe_api"></script>
      </Head>
      <NextSeo
        title={
          data?.coverStyle?.customName2
            ? `${data?.coverStyle?.customName} ${data?.coverStyle?.customName2}`
            : data?.coverStyle?.customName || url
        }
        description={metaDescription}
        canonical={url}
        openGraph={{
          url,
          type: "website",
          title: data?.coverStyle?.customName2
            ? `${data?.coverStyle?.customName} ${data?.coverStyle?.customName2}`
            : data?.coverStyle?.customName || url,
          description: metaDescription,
          siteName: url || window.location.host,
          images: [
            {
              url:
                data?.coverStyle?.mainImage?.url ||
                data?.theme?.coverStyle?.mainImage?.url ||
                logoBahagiaDay,
              width: 800,
              height: 600,
              alt: "Gambar Utama",
              type: "image/jpeg",
            },
          ],
        }}
        twitter={{
          handle: "Bahagia.day",
          site: "Bahagia.day",
          cardType: "summary_large_image",
        }}
      />
      {showPage && (
        <>
          {data?.backsoundUrl?.includes("music.youtube.com") ? (
            <div
              ref={iframeRef}
              style={{
                display: "none",
                pointerEvents: "none",
                opacity: 0,
                position: "fixed",
                top: 0,
                left: 0,
                width: "1px",
                height: "1px",
              }}
            />
          ) : (
            <audio
              ref={audioRef}
              src={data?.showBacksound && data?.backsound?.audioFile.url}
              loop
              playsInline // Add playsInline for iOS
              autoPlay={playing}
              controls={false}
            />
          )}

          {/* <ReactPlayer
            loop
            playing={playing}
            style={{ display: "none" }}
            pip={false}
            url={data?.showBacksound ? backsoundUrl : ""}
            config={{
              youtube: {
                playerVars: {
                  origin: "https://www.youtube.com",
                },
              },
            }}
          /> */}

          <div className="bg-white w-full">
            {guestCover && cover !== "false" ? (
              <MainContainer>
                <IndexMomentCover data={data} isMomentActived={true} />
              </MainContainer>
            ) : (
              <MainContainer>
                <IndexMomentDetail momentData={data} comments={comments} />
              </MainContainer>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Moment;

const MainContainer = styled("div", {
  "min-height": "100vh",
  display: "flex",
  "justify-content": "center",
  "max-width": "500px",
  "min-width": "auto",
  margin: "auto auto",
  // backgroundColor: 'blue',
});

export const getServerSideProps = async (context) => {
  const events = null; // Single declaration here
  try {
    const slugUrl = context.query.slug;
    const { host } = context.req.headers;
    const currentHost = getHostMapping(host);

    const urlParam = `filters[slugUrl]=${slugUrl}`;
    const activeFilter = "filters[isActive][$eq]=true";
    const brandFilter =
      currentHost === "official.bahagia.day" || currentHost === "localhost:3000"
        ? ""
        : `&filters[brand][domain]=${currentHost}`;

    // Backsound
    const backsound = "populate[backsound][populate][0]=audioFile";

    // event
    const event =
      "populate[event][fields][0]=uuid&populate[event][fields][1]=activePeriod&populate[event][populate][currentPlan][fields][0]=features";

    // GlobalStyle
    const mainStyle = "populate[globalStyle][populate][mainStyle][populate]=*";
    const globalSectionStyle =
      "populate[globalStyle][populate][sectionStyle][populate]=*";
    const typography =
      "populate[globalStyle][populate][typography][populate]=*";
    const imageAttributes =
      "populate[globalStyle][populate][imageAttributes][populate]=*";

    const globalStylePopulate = `${mainStyle}&${globalSectionStyle}&${typography}&${imageAttributes}`;

    // Cover Style
    const coverStyleMainImage =
      "populate[coverStyle][populate][mainImage][fields][0]=url";
    const coverStyleBackgroundImage =
      "populate[coverStyle][populate][backgroundImage][populate][image][fields][0]=url";
    const coverStyleMainImageRounded = `populate[coverStyle][populate][mainImageRounded][populate]=*`;
    const coverPopulate = `${coverStyleMainImage}&${coverStyleBackgroundImage}&${coverStyleMainImageRounded}`;

    // Section
    const section = "populate[section][populate][content][populate]=*";
    const sectionImage = "populate[section][populate][image][populate]=*";
    const sectionStyle = "populate[section][populate][style][populate]=*";
    const sectionPopulate = `${section}&${sectionImage}&${sectionStyle}`;

    // Theme
    const themeName = "populate[theme][fields][0]=name";
    const themeSection = "`populate[theme][populate][1]=section";
    // const themeCoverStyle = "populate[theme][populate][coverStyle][populate]=*";
    const themeCoverStyleImage =
      "populate[theme][populate][coverStyle][populate][backgroundImage][populate][image][fields][0]=url";
    // const themeCoverStyleImage = "populate[theme][populate][coverStyle][populate][backgroundImage][populate]=image";

    const themeBrandName = "populate[theme][populate][brand][fields][0]=name";
    const themeGlobalStyleSectionStyle =
      "populate[theme][populate][globalStyle][populate][sectionStyle][populate]=*";
    const themeGlobalStyleMainStyle =
      "populate[theme][populate][globalStyle][populate][mainStyle][populate]=*";
    const themeGlobalStyleTypography =
      "populate[theme][populate][globalStyle][populate][typography][populate]=*";
    const themeGlobalStyleImageAttributes =
      "populate[theme][populate][globalStyle][populate][imageAttributes][populate]=*";

    // const themePopulate = `${themeName}&${themeSection}&${themeCoverStyle}&${themeBrandName}&${themeGlobalStyleSectionStyle}&${themeGlobalStyleMainStyle}&${themeGlobalStyleTypography}&${themeGlobalStyleImageAttributes}`;
    const themePopulate = `${themeName}&${themeSection}&${themeCoverStyleImage}&${themeBrandName}&${themeGlobalStyleSectionStyle}&${themeGlobalStyleMainStyle}&${themeGlobalStyleTypography}&${themeGlobalStyleImageAttributes}`;

    const allPopulation = `${backsound}&${globalStylePopulate}&${coverPopulate}&${sectionPopulate}&${themePopulate}&${event}`;
    // const paramsKey = `${allPopulation}&${urlParam}&${activeFilter}${brandFilter}`;
    const paramsKey = `${allPopulation}&${urlParam}&${activeFilter}`;

    const fetchURL = `${apiURL}/api/pages?${paramsKey}`;

    const response = await fetch(fetchURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.API_SECRET}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.status}`);
    }

    const dataResponse = await response.json();
    const momentData = dataResponse.data || null;

    let comments = null;
    let events = null;

    if (momentData) {
      const commentsResponse = await fetch(
        `${apiURL}/api/comments?filters[page][uuid]=${momentData[0].uuid}&sort[0]=createdAt:desc`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.API_SECRET}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (commentsResponse.ok) {
        const commentsData = await commentsResponse.json();
        comments = commentsData.data;
      }

      // console.log("momentData: ", momentData[0].event);

      // Check if events are available and fetch if true
      if (momentData[0]?.event) {
        const fetchURL = `${apiURL}/api/events?filters[uuid]=${momentData[0]?.event?.uuid}&fields[0]=uuid&fields[1]=activePeriod&populate[currentPlan][fields][0]=features`;
        const eventsResponse = await fetch(fetchURL, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.API_SECRET}`,
            "Content-Type": "application/json",
          },
        });

        if (eventsResponse.ok) {
          const eventsData = await eventsResponse.json();
          events = eventsData?.data?.[0];
        }
      }
    }

    return {
      props: {
        momentData,
        comments,
        events,
        url: context.req.headers.host + context.req.url,
        cover: context.query.cover || "true",
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.error("Error in getServerSideProps:", error);

    return {
      props: {
        momentData: null,
        comments: null,
        events,
        url: context.req.headers.host + context.req.url,
        cover: context.query.cover || "true",
      },
    };
  }
};
