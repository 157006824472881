/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies

import dynamic from 'next/dynamic';
// import { WeddingCover } from './1/WeddingCover';

const MomentDetail1 = dynamic(() => import('./1/MomentDetail'));
const WeddingDetail2 = dynamic(() => import('./2/MomentDetail'));

// eslint-disable-next-line import/prefer-default-export
export const IndexMomentDetail = ({
  // wishes,
  momentData,
  isMomentThemeSampel,
  comments,
}:{
  // wishes,
  momentData?: any,
  isMomentThemeSampel?: any,
  comments?: any,
}) => {
  const layoutType = momentData?.layout?.type || 'type1';

  // console.log('themeCode: ', themeCode);
  return (
    <>
      {(layoutType === 'type1' || !layoutType) && (
        <MomentDetail1
          momentData={momentData}
          isMomentThemeSampel={isMomentThemeSampel}
          comments={comments}
        />
      )}
      {layoutType === 'type2' && (
        <WeddingDetail2
          momentData={momentData}
          isMomentThemeSampel={isMomentThemeSampel}
          comments={comments}
        />
      )}
    </>
  );
};
