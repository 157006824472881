/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies

import dynamic from 'next/dynamic';
// import { WeddingCover } from './1/WeddingCover';

const MomentCover1 = dynamic(() => import('./1/MomentCover'));
const MomentCover2 = dynamic(() => import('./2/MomentCover'));
const MomentCover3 = dynamic(() => import('./3/MomentCover'));
const MomentCover4 = dynamic(() => import('./4/MomentCover'));

// eslint-disable-next-line import/prefer-default-export
export const IndexMomentCover = ({
  isMomentActived,
  data,
  isMomentThemeSampel,
  themeName,
  themePrice,
}:any) => {
  const layoutType =
  data?.coverStyle.isActive ? data?.coverStyle?.layoutType : data?.theme?.coverStyle.layoutType;
  return (
    <>
      {(layoutType === 'v1' || !layoutType) && (
        <>
          <MomentCover1
            isMomentActived={isMomentActived}
            data={data}
            isMomentThemeSampel={isMomentThemeSampel}
            // themeName={themeName}
            // themePrice={themePrice}
          />
        </>
      )}

      {layoutType === 'v2' && (
        <MomentCover2
          isMomentActived={isMomentActived}
          data={data}
          isMomentThemeSampel={isMomentThemeSampel}
          // themeName={themeName}
          // themePrice={themePrice}
        />
      )}
      {layoutType === 'v3' && (
        <MomentCover3
          isMomentActived={isMomentActived}
          data={data}
          isMomentThemeSampel={isMomentThemeSampel}
          // themeName={themeName}
          // themePrice={themePrice}
        />
      )}
      {layoutType === 'v4' && (
        <MomentCover4
          isMomentActived={isMomentActived}
          data={data}
          isMomentThemeSampel={isMomentThemeSampel}
          // themeName={themeName}
          // themePrice={themePrice}
        />
      )}
    </>
  );
};
